import BaseAnalytics from "app/core/analytics";

class Analytics {
    constructor() {
        /**
         * @property eventCategory
         * @type {string}
         */
        this.eventCategory = "blogSubscribers";

        /**
         * @property eventActions
         * @type {Object}
         */
        this.eventActions = {
            showThanksForEmailDisplay: "show_thanks_for_email_display_blog_subscribers_popup",
            showEmailDisplay: "show_email_display_blog_subscribers_popup",
            showSorryBonusDisplay: "show_sorry_bonus_display_blog_subscribers_popup",
            sendEmail: "send_email_blog_subscribers_popup",
            emailVerified: "email_verified_blog_subscribers_popup"
        };

        /**
         * @property analytics
         * @type {Object}
         */
        this.analytics = BaseAnalytics.getInstance();

        this.sendEvent = this.sendEvent.bind(this);
    }

    /**
     * @public
     * @method showEmailDisplay
     * @returns {Analytics}
     */
    showEmailDisplay() {
        this.sendEvent(this.eventActions.showEmailDisplay);

        return this;
    }

    /**
     * @public
     * @method showThanksForEmailDisplay
     * @returns {Analytics}
     */
    showThanksForEmailDisplay() {
        this.sendEvent(this.eventActions.showThanksForEmailDisplay);

        return this;
    }

    /**
     * @public
     * @method showSorryBonusDisplay
     * @returns {Analytics}
     */
    showSorryBonusDisplay() {
        this.sendEvent(this.eventActions.showSorryBonusDisplay);

        return this;
    }

    /**
     * @public
     * @method sendEmail
     * @returns {Analytics}
     */
    sendEmail() {
        this.sendEvent(this.eventActions.sendEmail);

        return this;
    }

    /**
     * @public
     * @method showEmailVerifiedDisplay
     * @returns {Analytics}
     */
    showEmailVerifiedDisplay() {
        this.sendEvent(this.eventActions.emailVerified);

        return this;
    }

    /**
     * @private
     * @method sendEvent
     * @param eventAction {string|string[]}
     * @returns {Analytics}
     */
    sendEvent(eventAction) {
        this.analytics.sendGoogleAnalyticsEvent(this.eventCategory, eventAction);

        return this;
    }
}

export default Analytics;

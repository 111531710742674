/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import _ from "lodash";

import Resource from "app/core/resource";
import Env from "app/core/environment";

import MarketingService from "app/core/services/marketing";

import Validator from "app/core/utilites/validator/Validator";
import Algorithms from "app/core/utilites/validator/Algorithms";
import LocalStorage from "app/core/utilites/storage/localStorage";
import LocalStorageEnum from "app/core/utilites/enum/localStorageName";

import Analytics from "./Analytics";

class Modal extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @property _oneHourInMilliseconds
         * @type {number}
         */
        this._oneHourInMilliseconds = 3600000;

        /**
         * @property _validationErrors
         * @type {Object}
         */
        this._validationErrors = {
            email: {
                selector: ".error-email-field"
            }
        };

        this.state = {
            errorMessage: "",
            email: "",
            steps: {
                email: true,
                emailVerified: false,
                bonusForEmail: false,
                sorryBonus: false
            }
        };

        /**
         * @property _marketingService
         * @type {Object}
         */
        this._marketingService = MarketingService.getInstance();

        /**
         * @property _analytics
         * @type {Object}
         */
        this._analytics = new Analytics();

        /**
         * @property _HTMLResource
         * @type {Object}
         */
        this._HTMLResource = Resource.getHTML(Env.getInstance().getLanguage());

        /**
         * @property _linksResource
         * @type {Object}
         */
        this._linksResource = Resource.links;

        /**
         * @property _LocalStorage
         * @type {LocalStorage}
         */
        this._LocalStorage = LocalStorage.getInstance();

        /**
         * @property _LocalStorageEnum
         * @type {Enum}
         */
        this._LocalStorageEnum = LocalStorageEnum.getInstance();

        /**
         * @property _rootElementRef
         * @type {Object}
         */
        this._rootElementRef = React.createRef();

        this._Resource = Resource;
        this._Validator = Validator;
        this._Algorithms = Algorithms;

        /**
         * @property _stringsResource
         * @type {Object}
         */
        this._stringsResource = Resource.getStrings(Env.getInstance().getLanguage());

        this._changeEmail = this._changeEmail.bind(this);
        this._verifyEmail = this._verifyEmail.bind(this);
        this._close = this._close.bind(this);
    }

    componentDidMount() {
        this._setTimeOfLastShow();
        this._toEmailVerifiedStep();
    }

    /**
     * @method _isActiveEmailStep
     * @return {boolean}
     * @private
     */
    _isActiveEmailStep() {
        return this.state.steps.email;
    }

    /**
     * @method _isActiveEmailVerifiedStep
     * @return {boolean}
     * @private
     */
    _isActiveEmailVerifiedStep() {
        return this.state.steps.emailVerified;
    }

    /**
     * @method _isActiveBonusForEmailStep
     * @return {boolean}
     * @private
     */
    _isActiveBonusForEmailStep() {
        return this.state.steps.bonusForEmail;
    }

    /**
     * @method _isActiveSorryBonusStep
     * @return {boolean}
     * @private
     */
    _isActiveSorryBonusStep() {
        return this.state.steps.sorryBonus;
    }

    /**
     * @method _isValidFields
     * @param fields {Object}
     * @return {boolean}
     * @private
     */
    _isValidFields(fields) {
        let report = new this._Validator(fields, this._getAlgorithms()).validate();

        this._Validator.toggleValidateErrors(report, this._getRootElement());

        return !report.hasError();
    }

    /**
     * @method _isValidEmail
     * @return {boolean}
     * @private
     */
    _isValidEmail() {
        return this._isValidFields({email: this._getEmail()});
    }

    /**
     * @method _getRootElement
     * @returns {Object}
     * @private
     */
    _getRootElement() {
        return this._rootElementRef && this._rootElementRef.current;
    }

    /**
     * @method _getAlgorithms
     * @return {Object}
     * @private
     */
    _getAlgorithms() {
        return new this._Algorithms().getAlgorithms([
            {
                type: "email",
                selector: this._validationErrors.email.selector
            }
        ]);
    }

    /**
     * @method _getEmail
     * @return {string}
     * @private
     */
    _getEmail() {
        return this.state.email;
    }

    /**
     * @method _getErrorMessage
     * @return {Modal}
     * @private
     */
    _getErrorMessage() {
        return this.state.errorMessage;
    }

    /**
     * @method _setTimeOfLastShow
     * @return {Modal}
     * @private
     */
    _setTimeOfLastShow() {
        this._LocalStorage.setItem(
            this._LocalStorageEnum.getBlogSubscribersPopupAsValue(),
            {timeout: (Date.now() + this._oneHourInMilliseconds)}
        );

        return this;
    }

    /**
     * @private
     * @method _setStatusNeverShow
     * @return {Modal}
     */
    _setStatusNeverShow() {
        this._LocalStorage.setItem(this._LocalStorageEnum.getBlogSubscribersPopupAsValue(), {neverShow: true});

        return this;
    }

    /**
     * @private
     * @method _setStatusNeverShow
     * @return {Modal}
     */
    _setStatusShow() {
        this._LocalStorage.setItem(this._LocalStorageEnum.getBlogSubscribersPopupAsValue(), {neverShow: false});

        return this;
    }

    /**
     * @method _setErrorMessage
     * @param [message] {string}
     * @return {Modal}
     * @private
     */
    _setErrorMessage(message = "") {
        this.setState((state) => _.merge({}, state, {errorMessage: message}));

        return this;
    }

    /**
     * @method _changeEmail
     * @param e {Object}
     * @return {Modal}
     * @private
     */
    _changeEmail(e) {
        e.persist();

        this.setState((state) => _.merge({}, state, {email: e.target.value}));

        return this;
    }

    /**
     * @method _changeStep
     * @param changedStep {string}
     * @return {Modal}
     * @private
     */
    _changeStep(changedStep) {
        let steps = {
            email: false,
            сheckEmail: false,
            bonusForEmail: false,
            sorryBonus: false
        };

        steps[changedStep] = true;

        this.setState({steps});

        return this;
    }

    /**
     * @method _close
     * @return {Modal}
     * @private
     */
    _close() {
        this._setStatusNeverShow();
        this.props.close();

        return this;
    }

    /**
     * @method _toEmailVerifiedStep
     * @return {Modal}
     * @private
     */
    _toEmailVerifiedStep() {
        if (this.props.emailVerified) {
            this._analytics.showEmailVerifiedDisplay();
            this._setStatusShow();
            this._changeStep("emailVerified");
            this._setStatusNeverShow();
        }

        return this;
    }

    /**
     * @method _toBonusForEmailStep
     * @return {Modal}
     * @private
     */
    _toBonusForEmailStep() {
        this._analytics.showThanksForEmailDisplay();
        this._changeStep("bonusForEmail");
        this._setStatusNeverShow();

        return this;
    }

    /**
     * @method _toSorryBonusStep
     * @return {Modal}
     * @private
     */
    _toSorryBonusStep() {
        this._analytics.showSorryBonusDisplay();
        this._changeStep("sorryBonus");
        this._setStatusNeverShow();

        return this;
    }

    /**
     * @method _verifyEmail
     * @return {Modal}
     * @private
     */
    _verifyEmail() {
        if (this._isValidEmail()) {
            this._analytics.sendEmail();

            this
                ._marketingService
                .verifyEmailSubscribers(
                    this._getEmail(),
                    () => {
                        // eslint-disable-next-line no-underscore-dangle
                        this
                            ._setErrorMessage()
                            ._toBonusForEmailStep();
                    },
                    (error) => {
                        // eslint-disable-next-line no-underscore-dangle
                        this._setErrorMessage(error.getMessage())._toSorryBonusStep();
                    }
                );
        }

        return this;
    }

    /**
     * @public
     * @method render
     * @returns {React.ReactElement}
     */
    render() {
        return (
            <div className="blog-subscribers">
                <div className="blog-subscribers__body">
                    {this._isActiveEmailStep() && (
                        <div className="blog-subscribers__step step step--email">
                            <div className="step__header">
                                <div className="step__banner text-center">
                                    <img
                                        src={this._linksResource.images.welcomeBonus.email}
                                        alt="bannner"
                                        width="250"
                                        height="165"
                                    />
                                </div>
                            </div>

                            <div className="step__body">
                                <div
                                    className="step__title text-center"
                                    dangerouslySetInnerHTML={{__html: this._HTMLResource.blogSubscribers.email.title}}
                                />

                                <div
                                    className="step__description text-center line-height-1-5"
                                    dangerouslySetInnerHTML={{__html: this._HTMLResource.blogSubscribers.email.description}}
                                />

                                <div className="step__field">
                                    <div className="outlined-text-form">
                                        <InputMask
                                            autoFocus
                                            mask=""
                                            maskChar={null}
                                            type="text"
                                            className="form-control"
                                            required
                                            value={this._getEmail()}
                                            onChange={this._changeEmail}
                                        />

                                        <label>{this._stringsResource.yourEmail}</label>
                                    </div>

                                    <div className="error-message error-email-field" />

                                    <div className="error-message">
                                        {this._getErrorMessage()}
                                    </div>
                                </div>
                            </div>

                            <div className="step__footer">
                                <button
                                    type="button"
                                    className="btn-default btn-md btn-block text-uppercase"
                                    onClick={this._verifyEmail}
                                >
                                    {this._stringsResource.receive}
                                </button>
                            </div>
                        </div>
                    )}

                    {this._isActiveEmailVerifiedStep() && (
                        <div className="blog-subscribers__step step step--bonus">
                            <div className="step__header">
                                <div className="step__banner text-center">
                                    <img
                                        src={this._linksResource.images.welcomeBonus.bonus}
                                        alt="bannner"
                                        width="250"
                                        height="165"
                                    />
                                </div>
                            </div>

                            <div className="step__body">
                                <div
                                    className="step__title text-center"
                                    dangerouslySetInnerHTML={{__html: this._HTMLResource.blogSubscribers.emailVerified.title}}
                                />
                                <div
                                    className="step__description"
                                    dangerouslySetInnerHTML={{__html: this._HTMLResource.blogSubscribers.emailVerified.description}}
                                />
                            </div>

                            <div className="step__footer">
                                <button
                                    type="button"
                                    className="btn-default btn-md btn-block text-uppercase"
                                    onClick={this._close}
                                >
                                    {this._stringsResource.thank}
                                </button>
                            </div>
                        </div>
                    )}

                    {this._isActiveBonusForEmailStep() && (
                        <div className="blog-subscribers__step step step--bonus">
                            <div className="step__header">
                                <div className="step__banner text-center">
                                    <img
                                        src={this._linksResource.images.welcomeBonus.bonus}
                                        alt="bannner"
                                        width="250"
                                        height="165"
                                    />
                                </div>
                            </div>

                            <div className="step__body">
                                <div
                                    className="step__title"
                                    dangerouslySetInnerHTML={{__html: this._HTMLResource.blogSubscribers.bonusForEmail.title}}
                                />
                                <div
                                    className="step__description step__description--mb"
                                    dangerouslySetInnerHTML={{__html: this._HTMLResource.blogSubscribers.bonusForEmail.description}}
                                />
                            </div>

                            <div className="step__footer">
                                <button
                                    type="button"
                                    className="btn-default btn-md btn-block text-uppercase"
                                    onClick={this._close}
                                >
                                    {this._stringsResource.fine}
                                </button>
                            </div>
                        </div>
                    )}

                    {this._isActiveSorryBonusStep() && (
                        <div className="blog-subscribers__step step step--sorry-bonus">
                            <div className="step__header">
                                <div className="step__banner text-center">
                                    <img
                                        src={this._linksResource.images.welcomeBonus.sorryBonus}
                                        alt="bannner"
                                        width="69"
                                        height="65"
                                    />
                                </div>
                            </div>

                            <div className="step__body">
                                <div
                                    className="step__title text-center"
                                    dangerouslySetInnerHTML={{__html: this._HTMLResource.blogSubscribers.sorryBonus.title}}
                                />
                                <div
                                    className="step__description"
                                    dangerouslySetInnerHTML={{__html: this._HTMLResource.blogSubscribers.sorryBonus.description}}
                                />
                            </div>
                            <div
                                className="step__footer"
                                dangerouslySetInnerHTML={{__html: this._HTMLResource.blogSubscribers.sorryBonus.howGetMoreBonuses}}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    close: PropTypes.func,
    emailVerified: PropTypes.bool
};

Modal.defaultProps = {
    close: () => {},
    emailVerified: false
};

export default Modal;

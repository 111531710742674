import React from "react";
import PropTypes from "prop-types";

import LocalStorage from "app/core/utilites/storage/localStorage";
import LocalStorageEnum from "app/core/utilites/enum/localStorageName";
import ComponentClassNameEnum from "app/core/utilites/enum/componentClassName";
import ModalDialogService from "app/core/services/modalDialog";

import Modal from "./Modal";
import Analytics from "./Analytics";

class BlogSubscribers extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @property _analytics
         * @type {Object}
         */
        this._analytics = new Analytics();

        /**
         * @private
         * @property _componentClassNameEnumEnum
         * @type {Enum}
         */
        this._componentClassNameEnum = ComponentClassNameEnum.getInstance();

        /**
         * @property _localStorage
         * @type {LocalStorage}
         */
        this._localStorage = LocalStorage.getInstance();

        /**
         * @property _localStorageEnum
         * @type {Enum}
         */
        this._localStorageEnum = LocalStorageEnum.getInstance();

        /**
         * @property _modalDialogService
         * @type {ModalDialog}
         */
        this._modalDialogService = ModalDialogService.getInstance();

        /**
         * @property _timeoutForPopup
         * @type {number}
         */
        this._timeoutForPopup = 10000;

        this._show = this._show.bind(this);
    }

    componentDidMount() {
        this._open();
    }

    /**
     * @private
     * @method _hasPopupTimedOut
     * @returns {boolean}
     */
    _hasPopupTimedOut() {
        return this._getBlogSubscribersInfo() && this._getBlogSubscribersInfo().timeout < Date.now();
    }

    /**
     * @private
     * @method _isStatusNeverShow
     * @return {boolean}
     */
    _isStatusNeverShow() {
        return true;
    }

    /**
     * @private
     * @method _isThereDelayForPopup
     * @return {boolean}
     */
    _isThereDelayForPopup() {
        return this._getBlogSubscribersInfo() && this._getBlogSubscribersInfo().timeout;
    }

    /**
     * @private
     * @method _getBlogSubscribersInfo
     * @return {string}
     */
    _getBlogSubscribersInfo() {
        return this._localStorage.getItem(this._localStorageEnum.getBlogSubscribersPopupAsValue());
    }

    /**
     * @private
     * @method _open
     * @returns {BlogSubscribers}
     */
    _open() {
        if (!this._isStatusNeverShow()) {
            if (!this._isThereDelayForPopup()) {
                setTimeout(this._show, this._timeoutForPopup);
            } else {
                // eslint-disable-next-line no-unused-expressions
                this._showPopupAfterTimeout();
            }
        }

        if (this.props.emailVerified) {
            setTimeout(this._show, 0);
        }

        // setTimeout(this._show, 0);

        return this;
    }

    /**
     * @private
     * @method _show
     * @returns {BlogSubscribers}
     */
    _show() {
        this._analytics.showEmailDisplay();

        this._modalDialogService.open({
            size: this._modalDialogService.getSizes().getSm(),
            className: this._componentClassNameEnum.getBlogSubscribersModalAsValue(),
            body: (
                <Modal
                    close={() => this._modalDialogService.close()}
                    emailVerified={this.props.emailVerified}
                />
            )
        });

        return this;
    }

    /**
     * @private
     * @method _showPopupAfterTimeout
     * @returns {BlogSubscribers}
     */
    _showPopupAfterTimeout() {
        if (this._hasPopupTimedOut()) {
            setTimeout(this._show, this._timeoutForPopup);
        }

        return this;
    }

    render() {
        return null;
    }
}

BlogSubscribers.propTypes = {
    emailVerified: PropTypes.bool
};

BlogSubscribers.defaultProps = {
    emailVerified: false
};

export default BlogSubscribers;
